@use '@clr/ui/src/utils/mixins';
@use '@clr/ui/src/button/variables.buttons' as buttons-variables;
@use '@cds/core/tokens/tokens.scss';

@mixin square-off-btngroup-btns($dir: left) {
    border-top-#{$dir}-radius: 0;
    border-bottom-#{$dir}-radius: 0;
}

@include mixins.exports('button-group.clarity') {
    .agromaat-btn-group {
        display: inline-flex;
        margin-right: tokens.$cds-global-space-6;

        clr-dropdown {
            .dropdown-toggle {
                @include square-off-btngroup-btns(left);
                @include square-off-btngroup-btns(right);
            }

            &:last-child {
                .dropdown-toggle {
                    border-top-right-radius: buttons-variables.$clr-btn-border-radius;
                    border-bottom-right-radius: buttons-variables.$clr-btn-border-radius;
                }
            }
        }

        .agromaat-btn {
            margin: 0;
            overflow: hidden;

            label {
                height: 100%;
            }
        }

        .agromaat-btn {
            &:not(:first-child) {
                @include square-off-btngroup-btns(left);
            }

            &:not(:last-child) {
                @include square-off-btngroup-btns(right);
            }

            &:disabled:not(:last-child) {
                border-right: none;
            }
        }

        .tooltip {
            &:not(:first-child) .agromaat-btn {
                @include square-off-btngroup-btns(left);
            }

            &:not(:last-child) .agromaat-btn {
                @include square-off-btngroup-btns(right);
            }
        }

        &.agromaat-btn-primary,
        &.agromaat-btn-neutral,
        &.agromaat-btn-success,
        &.agromaat-btn-danger,
        &.agromaat-btn-warning {
            .tooltip:not(:last-child) > .agromaat-btn,
            > .agromaat-btn:not(:last-child) {
                // apply only to direct .agromaat-btn children
                // special margin right based on button border width
                margin: 0 buttons-variables.$clr-btn-border-width 0 0;
            }
        }

        > .agromaat-btn-group-overflow {
            position: relative;
        }

        > .agromaat-btn-group-overflow:last-child:not(:first-child) > .agromaat-btn:first-child {
            border-radius: 0 buttons-variables.$clr-btn-border-radius buttons-variables.$clr-btn-border-radius
                0;
        }

        > .agromaat-btn-group-overflow:last-child:first-child > .agromaat-btn:first-child {
            border-radius: buttons-variables.$clr-btn-border-radius;
        }

        //To avoid double borders
        .agromaat-btn:not([disabled]) + .agromaat-btn {
            border-left: none;
        }

        .tooltip:not(:first-child) .agromaat-btn {
            border-left: none;
        }

        .agromaat-btn:not([disabled]) + .agromaat-btn-group-overflow {
            .agromaat-btn {
                border-left: none;
            }
        }

        //Flat Buttons
        &.agromaat-btn-link .dropdown-toggle {
            min-width: 0;
        }

        //Flat Icon Buttons
        &.agromaat-btn-icon-link {
            &.agromaat-btn-link .agromaat-btn {
                min-width: 0;
            }
        }

        //Flat Icon Buttons All
        &.agromaat-btn-icon {
            .agromaat-btn {
                min-width: 0;
            }
        }

        //Icons and their corresponding title
        .clr-icon-title {
            display: none;
            text-transform: none;
        }

        .dropdown-toggle {
            display: block;
        }

        .dropdown-menu {
            cds-icon,
            clr-icon {
                display: none;
            }

            .clr-icon-title {
                display: inline;
            }
        }
    }

    // @deprecated last of deprecated forms CSS to keep btn groups working
    // should be removed when btn is revamped with clr- naming conventions
    .checkbox,
    .radio {
        position: relative;

        input[type='checkbox'],
        input[type='radio'] {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            @include mixins.equilateral(0);
            opacity: 0;
        }
    }

    .dropdown-menu.clr-button-group-menu {
        visibility: visible;
    }
}
