@supports (-webkit-overflow-scrolling: touch) {
    input[type='color'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='month'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    input[type='week'],
    select:focus,
    textarea {
        font-size: 16px;
    }
}
