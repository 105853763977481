@use 'sass:meta';
@use '@clr/ui/src/utils/mixins';
@use '@clr/ui/src/button/variables.buttons' as buttons-variables;
@use '@cds/core/tokens/tokens.scss';
@import '@clr/ui/src/utils/_variables.clarity.scss';

@function getSassButtonColor($type: default, $property: null) {
    @if not $property {
        @return null;
    }

    @if $type == default {
        @return buttons-variables.lookupFromDefaultButtonColors($property);
    }

    @if $type == default-outline {
        @return buttons-variables.lookupFromDefaultOutlineButtonColors($property);
    }

    @if $type == primary {
        @return buttons-variables.lookupFromPrimaryButtonColors($property);
    }

    @if $type == primary-outline {
        @return buttons-variables.lookupFromPrimaryOutlineButtonColors($property);
    }

    @if $type == success {
        @return buttons-variables.lookupFromSuccessButtonColors($property);
    }

    @if $type == success-outline {
        @return buttons-variables.lookupFromSuccessOutlineButtonColors($property);
    }

    @if $type == danger {
        @return buttons-variables.lookupFromDangerButtonColors($property);
    }

    @if $type == neutral {
        @return buttons-variables.lookupFromNeutralButtonColors($property);
    }

    @if $type == neutral-outline {
        @return buttons-variables.lookupFromNeutralOutlineButtonColors($property);
    }

    @if $type == danger-outline {
        @return buttons-variables.lookupFromDangerOutlineButtonColors($property);
    }

    @if $type == warning {
        @return buttons-variables.lookupFromWarningButtonColors($property);
    }

    @if $type == warning-outline {
        @return buttons-variables.lookupFromWarningOutlineButtonColors($property);
    }

    @if $type == link {
        @return buttons-variables.lookupFromLinkButtonColors($property);
    }

    @if $type == link-primary {
        @return buttons-variables.lookupFromLinkPrimaryButtonColors($property);
    }

    @if $type == link-success {
        @return buttons-variables.lookupFromLinkSuccessButtonColors($property);
    }

    @if $type == link-warning {
        @return buttons-variables.lookupFromLinkWarningButtonColors($property);
    }

    @if $type == link-danger {
        @return buttons-variables.lookupFromLinkDangerButtonColors($property);
    }

    @if $type == link-neutral {
        @return buttons-variables.lookupFromLinkNeutralButtonColors($property);
    }

    @if $type == inverse {
        @return buttons-variables.lookupFromInverseButtonColors($property);
    }

    @if $type == icon {
        @return buttons-variables.lookupFromIconButtonColors($property);
    }

    @return null;
}

@mixin button-css-var($button-type: default, $style: null, $styleNickname: $style) {
    @if $style {
        #{$style}: getSassButtonColor($button-type, $styleNickname);
    }
}

@mixin populateButtonProperties($button-type: default) {
    @include button-css-var($button-type, border-color);
    @include button-css-var($button-type, background-color, bg-color);
    @include button-css-var($button-type, color);

    cds-icon,
    clr-icon {
        @include button-css-var($button-type, color);
    }

    &:hover {
        @include button-css-var($button-type, background-color, hover-bg-color);
        @include button-css-var($button-type, color, hover-color);
        cds-icon,
        clr-icon {
            @include button-css-var($button-type, color, hover-color);
        }
    }

    &:active {
        @include button-css-var($button-type, background-color, active-bg-color);
        @include button-css-var($button-type, color, active-color);
        cds-icon,
        clr-icon {
            @include button-css-var($button-type, color, active-color);
        }
    }

    &.disabled,
    &:disabled {
        @include button-css-var($button-type, color, disabled-color);
        cursor: not-allowed;
        @include button-css-var($button-type, background-color, disabled-bg-color);
        @include button-css-var($button-type, border-color, disabled-border-color);
        cds-icon,
        clr-icon {
            @include button-css-var($button-type, color, disabled-color);
        }
    }
}

@mixin btn-checked-styles($button-type: default, $type: checkbox) {
    // add semantic prop: group-checked-font-color
    input[type='#{$type}']:checked + label {
        @include button-css-var($button-type, background-color, checked-bg-color);
        @include button-css-var($button-type, color, checked-color);

        // override icon colors on inverted backgrounds
        & > clr-icon,
        & > cds-icon {
            @include button-css-var($button-type, color, checked-color);
        }
    }

    input[type='#{$type}']:checked:hover + label {
        @include button-css-var($button-type, background-color, checked-hover-bg-color);
        @include button-css-var($button-type, color, checked-hover-color);

        // override icon colors on inverted backgrounds
        & > clr-icon,
        & > cds-icon {
            @include button-css-var($button-type, color, checked-hover-color);
        }
    }

    input[type='#{$type}']:checked:active + label {
        @include button-css-var($button-type, background-color, checked-active-bg-color);
        @include button-css-var($button-type, color, checked-active-color);

        // override icon colors on inverted backgrounds
        & > clr-icon,
        & > cds-icon {
            @include button-css-var($button-type, color, checked-active-color);
        }
    }

    &.disabled input[type='#{$type}']:checked + label,
    &:disabled input[type='#{$type}']:checked + label {
        @include button-css-var($button-type, color, checked-disabled-color);
        @include button-css-var($button-type, background-color, checked-disabled-bg-color);
        // override icon colors on inverted backgrounds
        & > clr-icon,
        & > cds-icon {
            @include button-css-var($button-type, color, checked-disabled-color);
        }
    }
}

@mixin btn-nowrap() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin btn-min-max-widths() {
    min-width: tokens.$cds-global-space-14;
    max-width: mixins.baselinePx(360);
}

@mixin btn-text-properties() {
    text-align: center;
    text-decoration: none;
    vertical-align: middle;

    &:hover {
        text-decoration: none;
    }
}

@mixin btn-default-border-styles() {
    border: buttons-variables.$clr-btn-border-width solid;
    border-radius: buttons-variables.$clr-btn-border-radius;
}

@mixin btn-appearance() {
    cursor: pointer;

    display: inline-flex;
    gap: tokens.$cds-global-space-5;
    align-items: center;
    place-content: center;

    // Overrides a Fix for IOS in BS4 _normalize.scss (~ln 330)
    // `!important` is to override specificity in this instance
    // But we wanted to preserve the normalize fix in non-Clarity buttons for iOS
    -webkit-appearance: none !important;
}

@mixin btn-sm-appearance() {
    @include mixins.generate-typography-token('BUTTON-11-SMALL');

    height: buttons-variables.$clr-btn-appearance-standard-height;
    padding: buttons-variables.$clr-btn-appearance-standard-padding;
}

@mixin generateButton() {
    @include btn-appearance();
    @include btn-default-border-styles();
    @include btn-min-max-widths();
    @include btn-nowrap();
    @include btn-text-properties();
}

@include meta.load-css('@clr/ui/src/button/properties.buttons');

@include mixins.exports('buttons.clarity') {
    .agromaat-btn {
        @include generateButton();
        @include populateButtonProperties(default);
        @include mixins.generate-typography-token('BUTTON-12');

        height: buttons-variables.$clr-btn-appearance-form-height;
        padding: buttons-variables.$clr-btn-appearance-form-padding;

        .clr-loading-btn-content {
            display: flex;
            gap: tokens.$cds-global-space-5;
            align-items: center;
        }
    }

    //Clarity Buttons
    .agromaat-btn-group > .agromaat-btn,
    .agromaat-btn {
        clr-icon {
            transform: translate3d(0, calc(-1 * #{tokens.$cds-global-space-2}), 0);
        }
    }

    //.agromaat-btn-outline-<state> is the new BS4 naming convention. We are using both for now
    // TODO: Refactor these classnames when the standard clarity naming convention is set.
    // This is a breaking change and will need an upgrade path so users know how to easily migrate to the new
    // version.
    a.agromaat-btn,
    .agromaat-btn.agromaat-btn-secondary,
    .agromaat-btn-secondary .agromaat-btn {
        @include populateButtonProperties(default);
    }

    .agromaat-btn.agromaat-btn-info,
    .agromaat-btn-info .agromaat-btn,
    .agromaat-btn.agromaat-btn-primary,
    .agromaat-btn-primary .agromaat-btn {
        @include populateButtonProperties(primary);
    }

    .agromaat-btn.agromaat-btn-success,
    .agromaat-btn-success .agromaat-btn {
        @include populateButtonProperties(success);
    }
    .agromaat-btn.agromaat-btn-warning,
    .agromaat-btn-warning .agromaat-btn {
        @include populateButtonProperties(warning);
    }

    .agromaat-btn.agromaat-btn-danger,
    .agromaat-btn-danger .agromaat-btn {
        @include populateButtonProperties(danger);
    }

    .agromaat-btn.agromaat-btn-neutral,
    .agromaat-btn-neutral .agromaat-btn {
        @include populateButtonProperties(neutral);
    }

    .agromaat-btn.agromaat-btn-outline,
    .agromaat-btn.agromaat-btn-outline .agromaat-btn,
    .agromaat-btn.agromaat-btn-secondary-outline,
    .agromaat-btn.agromaat-btn-outline-secondary,
    .agromaat-btn-secondary-outline .agromaat-btn,
    .agromaat-btn-outline-secondary .agromaat-btn,
    .agromaat-btn-outline .agromaat-btn {
        @include populateButtonProperties(default-outline);
    }

    .agromaat-btn.agromaat-btn-primary-outline,
    .agromaat-btn-outline-info .agromaat-btn,
    .agromaat-btn-info-outline .agromaat-btn,
    .agromaat-btn-primary-outline .agromaat-btn,
    .agromaat-btn-outline-primary .agromaat-btn,
    .agromaat-btn.agromaat-btn-outline-primary,
    .agromaat-btn.agromaat-btn-outline-info,
    .agromaat-btn.agromaat-btn-info-outline,
    .agromaat-btn.agromaat-btn-info-outline {
        @include populateButtonProperties(primary-outline);
    }

    .agromaat-btn.agromaat-btn-success-outline,
    .agromaat-btn.agromaat-btn-outline-success,
    .agromaat-btn-success-outline .agromaat-btn,
    .agromaat-btn-outline-success .agromaat-btn {
        @include populateButtonProperties(success-outline);
    }
    .agromaat-btn.agromaat-btn-warning-outline,
    .agromaat-btn.agromaat-btn-outline-warning,
    .agromaat-btn-warning-outline .agromaat-btn,
    .agromaat-btn-outline-warning .agromaat-btn {
        @include populateButtonProperties(warning-outline);
    }

    .agromaat-btn.agromaat-btn-danger-outline,
    .agromaat-btn.agromaat-btn-outline-danger,
    .agromaat-btn-danger-outline .agromaat-btn,
    .agromaat-btn-outline-danger .agromaat-btn {
        @include populateButtonProperties(danger-outline);
    }

    .agromaat-btn.agromaat-btn-neutral-outline,
    .agromaat-btn.agromaat-btn-outline-neutral,
    .agromaat-btn-neutral-outline .agromaat-btn,
    .agromaat-btn-outline-neutral .agromaat-btn {
        @include populateButtonProperties(neutral-outline);
    }

    .agromaat-btn.agromaat-btn-link,
    .agromaat-btn-link .agromaat-btn {
        @include populateButtonProperties(link);

        //To override the visited state of anchor links styled as buttons
        &:visited {
            @include button-css-var(link, color, visited-color);
            cds-icon,
            clr-icon {
                @include button-css-var(link, color, visited-color);
            }
            &:hover {
                @include button-css-var(link, color, visited-hover-color);
                cds-icon,
                clr-icon {
                    @include button-css-var(link, color, visited-hover-color);
                }
            }
        }
    }

    .agromaat-btn.agromaat-btn-link-primary,
    .agromaat-btn-link-primary .agromaat-btn {
        @include populateButtonProperties(link-primary);
    }

    .agromaat-btn.agromaat-btn-link-success,
    .agromaat-btn-link-success .agromaat-btn {
        @include populateButtonProperties(link-success);
    }

    .agromaat-btn.agromaat-btn-link-warning,
    .agromaat-btn-link-warning .agromaat-btn {
        @include populateButtonProperties(link-warning);
    }

    .agromaat-btn.agromaat-btn-link-danger,
    .agromaat-btn-link-danger .agromaat-btn {
        @include populateButtonProperties(link-danger);
    }

    .agromaat-btn.agromaat-btn-link-neutral,
    .agromaat-btn-link-neutral .agromaat-btn {
        @include populateButtonProperties(link-neutral);
    }

    .agromaat-btn.agromaat-btn-inverse,
    .agromaat-btn-inverse .agromaat-btn {
        @include populateButtonProperties(inverse);
    }

    .agromaat-btn.agromaat-btn-sm,
    .agromaat-btn-sm .agromaat-btn {
        @include btn-sm-appearance();
    }

    .agromaat-btn-block {
        display: flex;
        width: 100%;
        max-width: 100%;
    }

    .agromaat-btn {
        margin: buttons-variables.$clr-btn-vertical-margin buttons-variables.$clr-btn-horizontal-margin
            buttons-variables.$clr-btn-vertical-margin 0;

        &.agromaat-btn-link {
            margin-right: 0;
        }

        &.agromaat-btn-link.agromaat-btn-inverse {
            border-color: transparent;
        }
    }

    //Small Icon Button
    .agromaat-btn-sm:not(.agromaat-btn-link) {
        clr-icon {
            transform: translate3d(0, calc(-1 * #{tokens.$cds-global-space-1}), 0);
        }

        clr-icon,
        cds-icon {
            height: buttons-variables.$clr-btn-appearance-standard-icon-size;
            width: buttons-variables.$clr-btn-appearance-standard-icon-size;
        }
    }

    .agromaat-btn-icon {
        min-width: 0;
    }

    //Overflow
    .agromaat-btn-group {
        &.agromaat-btn-link .dropdown-toggle {
            @include populateButtonProperties(link);
        }

        &.agromaat-btn-sm .agromaat-btn-group-overflow > .dropdown-toggle {
            @include btn-sm-appearance();
        }
    }

    //Checkboxes styled as Buttons
    //Radio Buttons styled as Buttons
    .checkbox.agromaat-btn,
    .checkbox-inline.agromaat-btn,
    .radio.agromaat-btn,
    .radio-inline.agromaat-btn {
        padding: 0;

        label {
            @include btn-appearance();

            padding: 0 tokens.$cds-global-space-6;
        }

        &.disabled label,
        &:disabled label {
            cursor: not-allowed;
        }
    }

    .checkbox.agromaat-btn,
    .checkbox-inline.agromaat-btn {
        input[type='checkbox'] + label::before,
        input[type='checkbox'] + label::after {
            content: none;
        }
    }

    .radio.agromaat-btn,
    .radio-inline.agromaat-btn {
        input[type='radio'] + label::before,
        input[type='radio'] + label::after {
            content: none;
        }
    }

    .checkbox.agromaat-btn,
    .checkbox-inline.agromaat-btn {
        @include btn-checked-styles();

        label {
            width: 100%;
        }

        //.agromaat-btn-outline-<state> is the new BS4 naming convention
        //TODO: we support both the previous one and the new one but we will deprecate the old ones from the example in 0.5.0
        &.agromaat-btn-secondary,
        &.agromaat-btn-info,
        &.agromaat-btn-outline,
        &.agromaat-btn-primary-outline,
        &.agromaat-btn-secondary-outline,
        &.agromaat-btn-outline-primary,
        &.agromaat-btn-outline-secondary,
        &.agromaat-btn-info-outline,
        &.agromaat-btn-outline-info {
            @include btn-checked-styles();
        }

        &.agromaat-btn-primary {
            @include btn-checked-styles(primary);
        }

        &.agromaat-btn-success {
            @include btn-checked-styles(success);
        }

        &.agromaat-btn-warning {
            @include btn-checked-styles(warning);
        }

        &.agromaat-btn-danger {
            @include btn-checked-styles(danger);
        }

        &.agromaat-btn-success-outline,
        &.agromaat-btn-outline-success {
            @include btn-checked-styles(outline-success);
        }

        &.agromaat-btn-danger-outline,
        &.agromaat-btn-outline-danger {
            @include btn-checked-styles(outline-danger);
        }

        &.agromaat-btn-warning-outline,
        &.agromaat-btn-outline-warning {
            @include btn-checked-styles(outline-warning);
        }

        &.agromaat-btn-link {
            @include btn-checked-styles(link);
        }

        &.agromaat-btn-inverse {
            @include btn-checked-styles(inverse);
        }
    }

    .radio.agromaat-btn,
    .radio.agromaat-btn {
        @include btn-checked-styles(default, radio);

        label {
            width: 100%;
        }

        //.agromaat-btn-outline-<state> is the new BS4 naming convention. We are using both for now
        // TODO: Refactor these classnames when the standard clarity naming convention is set.
        // This is a breaking change and will need an upgrade path so users know how to easily migrate to the new
        // version.
        &.agromaat-btn-secondary,
        &.agromaat-btn-info,
        &.agromaat-btn-outline,
        &.agromaat-btn-primary-outline,
        &.agromaat-btn-secondary-outline,
        &.agromaat-btn-outline-primary,
        &.agromaat-btn-outline-secondary,
        &.agromaat-btn-info-outline,
        &.agromaat-btn-outline-info {
            @include btn-checked-styles(default, radio);
        }

        &.agromaat-btn-primary {
            @include btn-checked-styles(primary, radio);
        }

        &.agromaat-btn-success {
            @include btn-checked-styles(success, radio);
        }

        &.agromaat-btn-warning {
            @include btn-checked-styles(warning, radio);
        }

        &.agromaat-btn-danger {
            @include btn-checked-styles(danger, radio);
        }

        &.agromaat-btn-success-outline,
        &.agromaat-btn-outline-success {
            @include btn-checked-styles(success-outline, radio);
        }

        &.agromaat-btn-warning-outline,
        &.agromaat-btn-outline-warning {
            @include btn-checked-styles(warning-outline, radio);
        }

        &.agromaat-btn-danger-outline,
        &.agromaat-btn-outline-danger {
            @include btn-checked-styles(danger-outline, radio);
        }

        &.agromaat-btn-link {
            @include btn-checked-styles(link, radio);
        }

        &.agromaat-btn-inverse {
            @include btn-checked-styles(inverse, radio);
        }
    }
}
